import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | "Смак України: Майстерність Традиційної Кухні"
			</title>
			<meta name={"description"} content={"Історія нашої кулінарної подорожі яку ми хочемо розділити з тобою та твоїми друзями."} />
			<meta property={"og:title"} content={"About | \"Смак України: Майстерність Традиційної Кухні\""} />
			<meta property={"og:description"} content={"Історія нашої кулінарної подорожі яку ми хочемо розділити з тобою та твоїми друзями."} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" background="#e2e8f0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Історія нашої кулінарної подорожі яку ми хочемо розділити з тобою{"\n\n"}
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					Кулінарні курси "Смак України: Майстерність Традиційної Кухні" виникли з бажання відтворити та передати багатство української кулінарної спадщини. Їх історія розпочалася з пристрасті до українських страв та прагнення популяризувати їх смак та культурну спадщину. Курси надають можливість людям дізнатися секрети готування традиційних страв та поглибити знання про українську кухню, культуру, історію, спадщину.{"\n\n"}
				</Text>
				<Link
					href="/contacts"
					color="--lightD1"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-red"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
				>
					Взяти участь
				</Link>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/delicious-dumplings-with-cherries-jam_2829-12008.jpg?v=2023-10-06T15:36:20.272Z"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					object-position="0% 70%"
					sm-max-height="500px"
					height="100%"
					srcSet="https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/delicious-dumplings-with-cherries-jam_2829-12008.jpg?v=2023-10-06T15%3A36%3A20.272Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/delicious-dumplings-with-cherries-jam_2829-12008.jpg?v=2023-10-06T15%3A36%3A20.272Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/delicious-dumplings-with-cherries-jam_2829-12008.jpg?v=2023-10-06T15%3A36%3A20.272Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/delicious-dumplings-with-cherries-jam_2829-12008.jpg?v=2023-10-06T15%3A36%3A20.272Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/delicious-dumplings-with-cherries-jam_2829-12008.jpg?v=2023-10-06T15%3A36%3A20.272Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/delicious-dumplings-with-cherries-jam_2829-12008.jpg?v=2023-10-06T15%3A36%3A20.272Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/delicious-dumplings-with-cherries-jam_2829-12008.jpg?v=2023-10-06T15%3A36%3A20.272Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-vegetable-borscht-white-bowl_140725-10327.jpg?v=2023-10-06T15:34:34.416Z"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
						max-height="500px"
						srcSet="https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-vegetable-borscht-white-bowl_140725-10327.jpg?v=2023-10-06T15%3A34%3A34.416Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-vegetable-borscht-white-bowl_140725-10327.jpg?v=2023-10-06T15%3A34%3A34.416Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-vegetable-borscht-white-bowl_140725-10327.jpg?v=2023-10-06T15%3A34%3A34.416Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-vegetable-borscht-white-bowl_140725-10327.jpg?v=2023-10-06T15%3A34%3A34.416Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-vegetable-borscht-white-bowl_140725-10327.jpg?v=2023-10-06T15%3A34%3A34.416Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-vegetable-borscht-white-bowl_140725-10327.jpg?v=2023-10-06T15%3A34%3A34.416Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-vegetable-borscht-white-bowl_140725-10327.jpg?v=2023-10-06T15%3A34%3A34.416Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/view-tasty-food-assortment_23-2148859562.jpg?v=2023-10-06T15:34:50.822Z"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						srcSet="https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/view-tasty-food-assortment_23-2148859562.jpg?v=2023-10-06T15%3A34%3A50.822Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/view-tasty-food-assortment_23-2148859562.jpg?v=2023-10-06T15%3A34%3A50.822Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/view-tasty-food-assortment_23-2148859562.jpg?v=2023-10-06T15%3A34%3A50.822Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/view-tasty-food-assortment_23-2148859562.jpg?v=2023-10-06T15%3A34%3A50.822Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/view-tasty-food-assortment_23-2148859562.jpg?v=2023-10-06T15%3A34%3A50.822Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/view-tasty-food-assortment_23-2148859562.jpg?v=2023-10-06T15%3A34%3A50.822Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65201646cd81ee001f7ce217/images/view-tasty-food-assortment_23-2148859562.jpg?v=2023-10-06T15%3A34%3A50.822Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Запрошуємо вас приєднатися до нас і подарувати собі та своїм близьким незабутню кулінарну подорож у світ смаків, що проникають українську культуру та традиції.{"\n\n"}
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});